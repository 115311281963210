import React, { PropsWithChildren, useCallback } from 'react'
import Grid from '@/components/Grid'
import {
  ContentButtonItem,
  ContentHashMapItem,
  ContentImageItem,
  ContentRow,
  ContentTextItem,
  ContentVideoItem,
} from '@/types/Content'
import styled from '@emotion/styled'
import { GridVerticalAlignType } from '@/enums/Grid'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import TransitionHandler from '@/components/TransitionHandler'
import elementItemHelper from '@/helpers/elementItemHelper'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import {
  FONT_16,
  FONT_18_EXTRA_BOLD,
  FONT_22_EXTRA_BOLD,
  STYLE_KEEP_WORD,
} from '@/styles/fonts'
import { STYLE_ARROW_BUTTON_BLACK } from '@/styles/buttons'
import { EffectType } from '@/enums/Content'
import Video from '@/components/Video'
import AutoLinkText from '@/components/AutoLinkText'

interface Grid3MediaCardProps {
  item: ContentRow
}

interface Grid3MediaCardStyledProps {
  isLastColumn: boolean
}

const Partial = {
  Video({ video }: { video: ContentVideoItem[] }) {
    if (!video) return null
    return (
      <>
        {video?.map((item, index) => (
          <TransitionHandler
            effect={item?.effectType ?? EffectType.WIPE_RIGHT}
            order={item?.effectOrder}
            key={`Grid3MediaCard-video-${index}`}
          >
            <DisplayConfigWrapper show={item?.show}>
              <Styled.Video
                src={item?.src}
                imageSrc={item?.imageSrc}
                imageAlt={item?.imageAlt}
              />
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
  Image({ image }: { image: ContentImageItem[] }) {
    if (!image) {
      return null
    }
    return (
      <>
        {image?.map(({ src, alt, show }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`Grid3MediaCard-image-${index}`}
          >
            <Styled.Image src={src} alt={alt || ''} />
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  SubTitle({ subTitle }: { subTitle: ContentTextItem[] }) {
    if (!subTitle) {
      return null
    }
    return (
      <>
        {subTitle?.map(({ value, show }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`Grid3MediaCard-subTitle-${index}`}
          >
            <Styled.SubTitle>{value}</Styled.SubTitle>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  Title({ title }: { title: ContentTextItem[] }) {
    if (!title) {
      return null
    }
    return (
      <>
        {title?.map(({ value, show, as }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`Grid3MediaCard-title-${index}`}
          >
            <Styled.Title as={as}>{value}</Styled.Title>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  Text({ text }: { text: ContentTextItem[] }) {
    if (!text) {
      return null
    }
    return (
      <>
        {text?.map(({ value, show }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`Grid3MediaCard-text-${index}`}
          >
            <Styled.Text>{value}</Styled.Text>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  HashMap({ hashMap }: { hashMap: ContentHashMapItem[] }) {
    if (!hashMap) return null
    return (
      <>
        {hashMap?.map(({ key, value, show }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`Grid3MediaCard-button-${index}`}
          >
            <Styled.HashMapWrap>
              <Styled.HashMapRow>
                <Styled.HashMapKey>
                  <Styled.Label>{key}</Styled.Label>
                </Styled.HashMapKey>
                <Styled.HashMapValue>
                  <Styled.Label>{value}</Styled.Label>
                </Styled.HashMapValue>
              </Styled.HashMapRow>
            </Styled.HashMapWrap>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  Button({ button }: { button: ContentButtonItem[] }) {
    if (!button) return null
    return (
      <>
        {button?.map((item, index) => (
          <DisplayConfigWrapper
            show={item?.show}
            key={`Grid3MediaCard-button-${index}`}
          >
            <Styled.Button {...item}>{item?.label}</Styled.Button>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
}

function Grid3MediaCard({ item }: PropsWithChildren<Grid3MediaCardProps>) {
  const { columns, type } = item
  const columnsLength = columns.length

  const renderColumnElements = useCallback(
    ({ items }, columnIndex) => {
      const { image, title, text, button, subTitle, hashMap, video } =
        elementItemHelper(items)

      const isLastColumn = columnIndex === columnsLength - 1
      const key = `Grid3MediaCard-${columnIndex}`
      return (
        <Styled.CardWrap key={key} isLastColumn={isLastColumn}>
          <TransitionHandler>
            <Partial.Video video={video} />
            <Partial.Image image={image} />
            <Styled.CardBody>
              <Partial.SubTitle subTitle={subTitle} />
              <Partial.Title title={title} />
              <Partial.Text text={text} />
              <Partial.HashMap hashMap={hashMap} />
              <Partial.Button button={button} />
            </Styled.CardBody>
          </TransitionHandler>
        </Styled.CardWrap>
      )
    },
    [columnsLength]
  )

  return (
    <Grid gridType={type} verticalAlign={GridVerticalAlignType.TOP}>
      {columns.map(renderColumnElements)}
    </Grid>
  )
}

const Styled = {
  CardWrap: styled.div<Grid3MediaCardStyledProps>`
    padding: 0 6px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-bottom: ${({ isLastColumn }) => !isLastColumn && 60}px;
    }
  `,
  Video: styled(Video)`
    width: 100%;
  `,
  Image: styled.img`
    width: 100%;
    vertical-align: middle;
  `,
  CardBody: styled.div`
    padding-top: 50px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 24px;
    }
  `,
  SubTitle: styled.div`
    ${FONT_22_EXTRA_BOLD};
    color: ${STYLE_COLOR.BLACK03};
    white-space: pre-wrap;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_18_EXTRA_BOLD};
    }
  `,
  Title: styled.div`
    ${FONT_22_EXTRA_BOLD};
    white-space: pre-wrap;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_18_EXTRA_BOLD};
    }
  `,
  Text: styled.p`
    padding-top: 24px;
    ${FONT_16};
    color: ${STYLE_COLOR.BLACK02};
    white-space: pre-wrap;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 12px;
    }
  `,
  HashMapWrap: styled.div`
    display: table;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
    padding-top: 20px;
  `,
  HashMapRow: styled.div`
    display: flex;
  `,
  HashMapKey: styled.div`
    flex-shrink: 0;
    flex-basis: 64px;
    white-space: nowrap;
    ${FONT_16};
    color: ${STYLE_COLOR.BLACK02};
    padding-right: 16px;
  `,
  HashMapValue: styled.div`
    position: relative;
    ${FONT_16};
    color: ${STYLE_COLOR.BLACK02};
    padding-left: 16px;
    height: 100%;
    border-left: 1px solid ${STYLE_COLOR.BLACK05};
  `,
  Label: styled.span`
    ${STYLE_KEEP_WORD};
  `,
  Button: styled(AutoLinkText)`
    ${STYLE_ARROW_BUTTON_BLACK};
    padding-top: 30px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 12px;
    }
  `,
}

export default Grid3MediaCard
